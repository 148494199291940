<template>
  <LuckyGrid ref="LuckDraw" width="240px" height="240px" :prizes="prizes" :blocks="blocks" :buttons="buttons"
    :default-style="defaultStyle" :active-style="activeStyle" @start="startCallBack" @end="endCallBack"
    :default-config="defaultConfig" />
</template>

<script>
  import {
    getLotteryDemo,
    startLottery,
    whetherLottery,
  } from '@/api/api'
  export default {
    props: {
      "lotteryId": String
    },

    data() {
      return {
        result: [], // 抽奖结果
        prizes: [], // 用于抽奖组件的数据显示
        lotteryData: [], // 用于获取后端传来的奖品信息
        blocks: [{
            padding: '1px',
            background: '#e2cea3',
            borderRadius: '13px'
          },
          {
            padding: '5px 0px',
            background: '#f3ecdc',
            borderRadius: '13px'
          },
          {
            padding: '1px',
            background: '#e2cea3',
            borderRadius: '8px'
          },
          {
            padding: '15px 10px',
            background: '#fffcf5',
            borderRadius: '8px'
          },
        ],
        defaultConfig: {
          accelerationTime: '3000',
          decelerationTime: '3000',
        },
        buttons: [{
          x: 1,
          y: 1,
          background: 'rgba(238, 154, 73, 0.7)',
          fonts: [{
            text: '开始抽奖',
            fontSize: '12px',
            top: '35%'
          }]
        }],
        defaultStyle: {
          background: '#ffefd6',
          borderRadius: '5px',
          fontColor: '#755c28',
          fontSize: '10px',
          lineHeight: '12px'
        },
        activeStyle: {
          background: '#de7247',
          fontColor: '#ffefd6',
        }
      }
    },
    created() {
      this.getPrizesList()
    },
    methods: {
      async getPrizesList() {
        let prizes = []
        let axis = [
          [0, 0],
          [1, 0],
          [2, 0],
          [2, 1],
          [2, 2],
          [1, 2],
          [0, 2],
          [0, 1]
        ]

        let res = await getLotteryDemo({
          lotteryId: this.lotteryId,
        })
        if (res.code === 200) {
          this.lotteryData = res.data.lotteryLayout;
          this.lotteryData.forEach((item, index) => {
            prizes.push({
              x: axis[index][0],
              y: axis[index][1],
              title: item.desc,
              // 没有图片显示文字，测试用，若只需图片请将下方fonts删除
              fonts: [{
                text: item.desc,
                fontSize: '12px',
                top: '35%',
                lineClamp: 2,
              }]
              // 如果有图片在这往下写
            })
          })
          this.prizes = prizes;
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          });
        }
      },

      async startCallBack() {
        // 判断用户是否已经抽过奖了
        if (sessionStorage.getItem('hadLottery')) {
          this.$message({
            type: 'info',
            message: '您已经抽过奖啦',
          });
          return;
        }else{
          let res = await whetherLottery({
            lotteryId: this.lotteryId,
            userAccount: JSON.parse(sessionStorage.getItem('user')).ecoAccountId
          })
          if (res.message === '该用户已抽奖') {
            sessionStorage.setItem('hadLottery', true);
            this.$message({
              type: 'info',
              message: '您已经抽过奖啦',
            });
            return;
          }
        }

        // 请求后端接口，获取到最终停止位置，确定奖品
        let res = await startLottery({
          lotteryId: this.lotteryId,
          userAccount: JSON.parse(sessionStorage.getItem('user')).ecoAccountId,
          list: this.lotteryData
        })

        if (res.code === 200) {
          this.result = res.data.result;
          sessionStorage.setItem('hadLottery', true);
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          });
        }

        this.$refs.LuckDraw.play()
        setTimeout(() => {
          this.$refs.LuckDraw.stop(this.result[0].indexNum - 1)
        }, 4000)
      },

      endCallBack(prize) {
        this.$emit("getPrize", prize.title, this.result[0].awardId, this.result[0].level);
      }
    }
  }
</script>