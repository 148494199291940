<!--
程序名：感谢页面
功能：填写完问卷跳转的页面
-->
<template>
  <div class="thankYou" v-loading="viewLoading">
    <div class="content" shadow="never">
      <i class="el-icon-circle-check" style="color: green;font-size: 50px" aria-hidden="true" /><br>
      您的回答已提交，感谢您的参与！<br><br>
      <!-- 此活动若有抽奖活动，则显示抽奖界面 -->
      <lottery v-if="lotteryExist" style="margin: 0 auto;" :lotteryId="lotteryId" @getPrize="showDialog" />
      <el-divider></el-divider>
      <el-link type="info">华讯调研问卷系统&nbsp;提供技术支持</el-link>
    </div>

    <van-dialog 
      v-model="winnerDialogVisible" 
      title="填写收货信息" 
      :show-cancel-button="true" 
      cancelButtonText="关闭"
      cancel-button-color="red" 
      @cancel="giveUpAward" 
      :show-confirm-button="true" 
      confirmButtonText="提交"
      confirmButtonColor="#36857a" 
      @confirm="submitForm" 
      :beforeClose="beforeClose">
      <van-form @submit="addRecordOnPhone" ref="vanForm" :colon="true">
        <van-field v-model="winnerInfo.punterName" name="name" label="收货人姓名" placeholder="请输入收货人姓名" :required="true"
          label-width="7.5em" :rules="[{ required: true }]" :show-error="false"></van-field>
        <van-field v-model="winnerInfo.phone" type="tel" name="phone" label="收货人联系方式" placeholder="请输入收货人联系方式"
          :required="true" label-width="7.5em"
          :rules="[{ required: true},
          {pattern: /(^((\d{11})|(\d{7,8})|(\d{4}|\d{3})-(\d{7,8}))$)/, message:'请输入合法的联系方式'}]" :show-error="false"></van-field>
        <van-field v-model="winnerInfo.address" name="address" label="收货人地址" placeholder="请输入收货地址" :required="true"
          label-width="7.5em" :rules="[{ required: true}]" :show-error="false"></van-field>
        <van-field v-model="winnerInfo.postcode" name="address" label="收货人地址邮编" placeholder="请输入收货地址邮编"
          label-width="7.5em" :rules="[{validator, message:'请输入合法的邮编' }]" :show-error="false"></van-field>
      </van-form>
    </van-dialog>

    <van-dialog 
      v-model="messageDialogVisible"
      :show-cancel-button="true"
      cancelButtonText="关闭"
      cancel-button-color="red" 
      :show-confirm-button="isBingo && ifFillPunter"
      :confirmButtonText="messageDialogConfirmButtonText"
      confirmButtonColor="#36857a" 
      @confirm="messageDialogConfirmButton" 
      @cancel="messageDialogClose">
      <slot name="title">
        <div v-if="isBingo" style="margin-top:20px; font-size:18px">恭喜你获得{{prizeLevel}}等奖</div>
        <div v-else style="margin-top:20px; font-size:18px">很遗憾您未中奖</div>
      </slot>
      <h3 style="margin-left:15px;margin-right:15px">{{prizeTitle}}</h3>
      <div v-if="isBingo" style="margin:0px 15px">{{lotteryMessage}}</div>
      <br />
    </van-dialog>
  </div>
</template>
<script>
  import Lottery from './Lottery.vue'
  import wx from 'weixin-js-sdk';

  import {
    getLottery,
    saveDeliverInfo,
  } from '@/api/api'

  export default {
    components: {
      Lottery,
    },

    data() {
      return {
        isBingo: true, // 是否中奖
        prizeLevel: '', // 奖品等级
        lotteryMessage: '', // 抽奖活动设置的消息
        prizeTitle: '', //奖品名称
        ifFillPunter: false, // 是否需要填写收货信息
        messageDialogConfirmButtonText: '确认', // 消息弹框确认按钮文本
        paperId: sessionStorage.getItem('paperId'),
        lotteryId: sessionStorage.getItem('lotteryId'),
        lotteryExist: false,
        messageDialogVisible: false, // 获奖后提示信息的弹框显示
        winnerDialogVisible: false, // 获奖后填写收货信息的弹出框显示
        winnerInfo: { // 收货信息
          punterName: '', // 收货人姓名
          phone: '', // 收货人联系方式
          address: '', // 收货地址
          awardId: '', // 奖品id
          postcode: '', // 邮编
          userAccount: JSON.parse(sessionStorage.getItem('user')).ecoAccountId, // 抽奖人的ecoId
        },
        viewLoading: false,
      }
    },
    async created() {
      this.viewLoading = true;
      let res = await getLottery({
        paperId: this.paperId,
      })

      setTimeout(() => {
        this.viewLoading = false;
      }, 700);

      if (res.code === 200 && res.data.lottery) {
        this.lotteryMessage = res.data.lottery.message;
        this.ifFillPunter = res.data.lottery.isFillPunter === '1' ? true : false;
        this.messageDialogConfirmButtonText = this.ifFillPunter ? '填写收货信息' : '确认';
        // 抽奖存在且抽奖开启
        if (res.data.lottery && res.data.lottery.state === '1') {
          this.lotteryExist = true;
        } else {
          this.lotteryExist = false;
        }
      }

      // 不存在抽奖活动，且sessionStorage里面有redirectUri，那么直接跳回市场活动
      if (!this.lotteryExist && sessionStorage.getItem('type') === '0' && sessionStorage.getItem('redirectUri')) {
        this.redireToMarketAfterAWhile();
      }
    },

    methods: {
      // 邮编校验
      validator(val) {
        if (val === '') {
          return true;
        }else{
          return /^[0-9]{6}$/.test(val);
        }
      },

      // 恭喜弹框确认按钮
      messageDialogConfirmButton() {
        if (this.messageDialogConfirmButtonText !== '确认') {
          this.winnerDialogVisible = true
        } else {
          wx.closeWindow();
          this.winnerDialogVisible = false;
        }
      },

      // 恭喜弹框关闭
      messageDialogClose() {
        wx.closeWindow();
      },

      // 禁止dialog点击确认后关闭
      beforeClose(action, done) {
        if (action === 'confirm') {
          return done(false)
        } else {
          return done();
        }
      },

      // 点击提交按钮
      submitForm() {
        this.$refs.vanForm.submit();
      },

      // 放弃奖品
      giveUpAward() {
        wx.closeWindow();
      },

      // 奖品等级数字转汉字
      transformLevel(prizeLevel) {
        switch (prizeLevel) {
          case "1":
            return '一'
          case "2":
            return '二'
          case "3":
            return '三'
          case "4":
            return '四'
          case "5":
            return '五'
          case "6":
            return '六'
          case "7":
            return '七'
          case "8":
            return '八'
          default:
            return '特'
        }
      },

      // 倒计时跳转回市场活动
      redireToMarketAfterAWhile() {
        const toast = this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: '3 秒后跳转回市场活动',
        });
        let second = 3;
        const timer = setInterval(() => {
          second--;
          if (second) {
            toast.message = `${second} 秒后跳转回市场活动`;
          } else {
            clearInterval(timer);
            this.$toast.clear();
            this.redireToMarket();
          }
        }, 1000);
      },
      // 显示填写收货信息的弹框
      showDialog(prizeTitle, awardId, prizeLevel) {
        this.prizeLevel = this.transformLevel(prizeLevel);
        this.prizeTitle = prizeTitle;
        this.winnerInfo.awardId = awardId;

        if (prizeTitle === '谢谢参与') {
          this.isBingo = false;
          this.messageDialogVisible = true;
        }

        if (awardId !== undefined) {
          this.isBingo = true;
          this.messageDialogVisible = true;
        } 
        // else {
        //   // 是预报名问卷且有redirectUri才会跳回市场活动
        //   if (sessionStorage.getItem('type') === '0' && sessionStorage.getItem('redirectUri')) {
        //     this.redireToMarketAfterAWhile();
        //   }
        // }
      },
      // 保存收货信息
      async addRecordOnPhone() {
        let res = await saveDeliverInfo({
          paperId: this.paperId
        }, this.winnerInfo);
        if (res.code === 200) {
          // this.$message({
          //   type: 'success',
          //   message: '收货信息填写成功',
          // })
          this.$toast.success('收货信息填写成功');
          setTimeout(() => {
            wx.closeWindow();
          }, 1000);
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          })
        }
        this.winnerInfo = {
          punterName: '',
          phone: '',
          address: '',
          awardId: '',
          postcode: '',
        };
        this.winnerDialogVisible = false;
      },

      // 跳转回市场活动
      redireToMarket() {
        let uri = sessionStorage.getItem('redirectUri');
        if (uri) {
          location.replace(decodeURIComponent(uri));
        } else {
          return;
        }
      },
    }
  }
</script>

<style scoped>
  .thankYou {
    padding: 10px;
  }

  .content {
    font-size: 18px;
    line-height: 40px;
    padding: 10px;
  }
</style>